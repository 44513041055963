import React, { useState, useEffect, useRef } from 'react'

import {
  WrapHMSBanner,
  HMSContent,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  TopTitle,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/BVRSbanner.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://app.rentl.io/en/sign-up'
  }

  return (
    <WrapHMSBanner>
      <HMSContent>
        <BannerTextBlock>
          <TopTitle>Hotel Property Management System’</TopTitle>

          <Title>Best Vacation Rental, Apartments, and Villas Management Software</Title>
          <Text>Empower your vacation rental business using easy-to-use, complete, and out-of-the-box software.</Text>
          <Button onClick={(e) => handleClick(e)}>Get a Free Trial</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <img src={bannerImg1} />
        </BannerImageBlock>
      </HMSContent>
    </WrapHMSBanner>
  )
}

export default Banner

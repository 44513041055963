import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const WrapHMS = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  max-width: 1860px;
  min-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-width: unset;
    width: 100vw;
  }
  @media only screen and (max-width: 425px) {
  }
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'

import FAQauthority from '../../components/hotel-property-management-system/FAQauthority'
import GetStartedCta from '../../components/GetStartedCTA'
import Banner from '../../components/Best-Vacation-Rental-Software-en/banner'
import Content from '../../components/Best-Vacation-Rental-Software-en/content'
import schema from '../../components/Best-Vacation-Rental-Software-en/schema.json'
const faqData = [
  {
    content:
      'Vacation rental software is a property management solution used by apartment, villa, and resort owners to manage everything from pre-booking to checkout and beyond. <a href="https://rentl.io/en/vacation-rentals">Find out more about Rentlio Vacation Rental Software.</a> ',
    title: 'What is vacation rental software?'
  },
  {
    content:
      'The average vacation rental cost in 2023 is 9 euros per unit. The cast varies based on the number of units and complexity setup. <a href="https://rentl.io/en/pricing">Calculate the cost of Rentlio vacation rental software.</a>',
    title: 'What is the price of vacation rental software?'
  },
  {
    content:
      'Simple. <a href="https://app.rentl.io/en/sign-up">Start a free trial</a> and learn how easy it is to set up and use Rentlio vacation rental software.',
    title: 'How to start using vacation rental software?'
  }
]
const cta_mobile_apps = {
  title: 'READY TO GET STARTED?',
  description:
    "Create your account and invite your team. You'll save time and earn more. Give us a try; you won't regret it!"
}

const HPMS = ({ location }) => {
  return (
    <Layout locale="en">
      <SEO
        title="#1 Vacation Rental Software l Rentlio"
        description="Discover vacation rental software's key features and functionalities and learn how to run your property more efficiently. "
        pathname={location.pathname}
        lang="en"
        location={location}
        schemaMarkup={schema}
      />
      <Header mode="dark" locale="en" color={'#FFFFFF'} opacity={0.3} location={location} hasTranslation="false" />
      <WrapHMS>
        <Banner />
        <Content locale="en" />
      </WrapHMS>
      <FAQauthority questions={faqData} title="FAQ" authority />
      <GetStartedCta data={cta_mobile_apps} locale="en" location={location} />

      <Footer locale="en" location={location} />
    </Layout>
  )
}

export default HPMS

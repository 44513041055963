import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent,
  SpaceBlock
} from './styles'
import Img from 'gatsby-image'

import RCM from '../../../assets/images/RCM.png'
import BVRSCM from '../../../assets/images/BVRSchannelManager.png'
import RBE from '../../../assets/images/RBE.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RentlioIntegracije from '../../../assets/images/HMSintegrations.png'
import RentlioKalendar from '../../../assets/images/BVRSkalendar.png'
import Reporting from '../../../assets/images/reportingDashboard.png'
import Housekeeping from '../../../assets/images/Housekeeping.png'
import Statistika from '../../../assets/images/Statistika.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Key Benefits of Using Vacation Rental Software'
  },
  {
    id: 2,
    title: 'Key Features and Functionalities of Using Vacation Rental Software'
  },
  {
    id: 3,
    title: 'How to choose the right Vacation Rental Software?'
  },
  {
    id: 4,
    title: 'Vacation Rental Software Cost,Implementation, Onboarding, Support'
  }
]

const Content = ({}) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/property-management-system'
  }
  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>In-page topics</TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <H2>What is a Vacation Rental Software?</H2>
        <P>
          Vacation rental software is a property management solution used by apartment, villa, and resort owners to
          manage everything from pre-booking to checkout. In today's digital environment, choosing a reliable software
          provider that offers connection and synchronization of all booking channels, such as booking.com, Airbnb,
          Expedia, and other marketplaces, is essential.
        </P>
        <P>
          Comprehensive cloud-based vacation rental software simplifies administrative tasks, including check-in
          procedures, calendar and pricing management, billing processes, adherence to local tax regulations, data
          analysis, and much more.
        </P>
        <P>
          Below you will find the key features and functionalities of{' '}
          <a href="https://rentl.io/en/vacation-rentals">Rentlio's vacation rental software</a> and how they can help
          you manage your property more efficiently.
        </P>
        <H2 id="1" className="sections">
          Key Benefits of Using Vacation Rental Software
        </H2>

        <P>
          Vacation rental software offers several key benefits for property owners and managers in the hospitality
          industry. These advantages contribute to streamlined operations, enhanced guest experiences, and improved
          efficiency.
        </P>
        <P>
          One significant benefit of using vacation rental software like Rentlio is automating administrative tasks. The
          platform allows property managers to automate booking processes, reservation confirmations, and payment
          transactions. This automation reduces the workload and minimizes the risk of manual errors, ensuring accurate
          and efficient reservation handling.
        </P>
        <P>
          Another crucial advantage is the centralized management of property listings. Rentlio provides a single
          platform to manage all aspects of multiple vacation rental properties. Property owners can easily update
          rates, availability, and property details across various booking channels simultaneously. This centralized
          control helps maintain consistency and coherence across different online platforms, leading to better
          visibility and increased bookings.
        </P>
        <P>
          Vacation rental software often comes equipped with robust reporting and analytics features. Property owners
          can access insightful data on booking trends, occupancy rates, and revenue performance. These analytics
          empower decision-making, enabling owners to adjust pricing strategies, marketing efforts, and property
          management practices to maximize profitability. The integration of communication tools is another key benefit.
          Rentlio facilitates seamless communication between property managers and guests through its platform.
          Automated messaging for booking confirmations, check-in instructions, and post-stay feedback helps enhance the
          guest experience. Efficient communication significantly improves customer satisfaction and helps create a
          positive reputation for the property.
        </P>
        <P>
          The channel management feature in vacation rental software is particularly valuable. Rentlio integrated PMS
          and channel manager allow property owners to connect with various online booking platforms, ensuring that
          availability and rates are synchronized across all channels. This prevents overbookings and helps reach a
          wider audience by tapping into different online marketplaces, ultimately increasing the property's visibility
          and revenue potential.
        </P>
        <GreyBlock>
          <H2 id="2" className="sections">
            Key Features and Functionalities of Using Vacation Rental Software
          </H2>
          <P>
            Most users choose software to help them manage and simplify their day-to-day business. Knowing all features
            and functionalities of vacation rental software can help run your property more efficiently, which leads to
            better productivity, guest satisfaction, and, eventually, more bookings and revenue.
          </P>
          <H3>Channel Manager</H3>
          <P>
            Channel manager helps manage and receive reservations from all OTA channels such as Booking.com, Airbnb,
            Expedia, and others. Also, the channel manager synchronizes all channels, so when a reservation arrives from
            booking.com, the availability automatically updates across other channels. You won't have to worry about
            overbookings with an integrated channel manager and vacation rental software.
          </P>
          <P>
            Also, you can update availability and price changes directly inside the calendar module of vacation rental
            software. The changes you make will be synchronized and visible across all channels in seconds immediately.
          </P>
          <P>
            <a href="https://rentl.io/en/channel-manager">Find out more about Rentlio's integrated channel manager.</a>
          </P>
          <FullWidthImg style={{ width: '60%' }}>
            <img src={RCM} />
          </FullWidthImg>
          <H3>Interactive Calendar</H3>
          <P>
            Think of an interactive calendar as the heart of vacation rental software. This is where you can view and
            manage all bookings and reservations, prices, and availability of all units. An interactive calendar with a
            simple drag-and-select feature allows you to control all bookings, which are always in synch across all OTA
            channels.
          </P>
          <P>
            Multiple calendar view options will give you a full 90-day overview. Flexible and accessible across all
            devices makes it simple to access the software at any time of the day.
          </P>
          <FullWidthImg>
            <img src={RentlioKalendar} />
          </FullWidthImg>
          <ImgTitle>Interactive Calendar</ImgTitle>
          <H3>ID and Passport Scanner</H3>
          <P>
            The passport and ID scanner available on the mobile app simplifies the check-in process. In a few seconds,
            you will directly have all customers’ information from ID or passport in the Rentlio PMS.
          </P>
          <P>
            Forget about typing names, ID numbers, and other information on paper and manually uploading information to
            the local authorities. With Rentlio's integrated ID and passport scanner, you will save time, eliminate
            errors, and simplify the check-in process.
          </P>
          <H3>Mobile App</H3>
          <P>
            Rentlio's mobile app allows you to manage the entire property. Most customers don't open their laptops since
            the mobile version offers a perfect user experience. This allows Rentlio users to stay in touch with their
            guests and manage their vacation rentals anytime and from any location.
          </P>
          <FullWidthImg>
            <img src={MobileApps} />
          </FullWidthImg>
          <ImgTitle>Rentlio Mobile App</ImgTitle>
          <H3>Billing and Invoicing</H3>
          <P>
            Rentlio's billing and invoicing module makes it easy to view all invoices, payments, and cancellations in
            one place. You can check the outstanding balance and payment method for each reservation.
          </P>
          <P>
            Also, you will have the option to print or send invoices via email. Most guests will receive invoices
            through OTA channels, but you must also provide a receipt by local authorities. Rentlio offers the option to
            customize invoices based on your wishes and needs.
          </P>
          <H3>Reporting and Analytics</H3>
          <P>
            Rentlio's reporting and analytics module monitors earnings and bookings month-on-month. Over 50 interactive
            graphs will show where customers arrive, how long they stay, which channel they book from, and much more.
          </P>
          <P>
            The date will help you make strategic decisions and increase the price per unit. Not only that, but it will
            also help you double down on your sales and marketing activities based on the statistics.
          </P>
          <FullWidthImg>
            <img src={Reporting} />
          </FullWidthImg>
          <ImgTitle>Rentlio Reporting Dashboard</ImgTitle>
        </GreyBlock>
        {/* <Form locale="en" /> */}
        <H2 id="3" className="sections">
          How to choose the right Vacation Rental Software?
        </H2>
        <P>
          There are several questions to ask yourself before selecting vacation rental software. Below you can see the
          key factors you must consider before choosing software.
        </P>
        <PUL>
          <ul>
            <li>Do they offer a cloud-based solution?</li>
            <li>Can you manage reservations across all OTA channels?</li>
            <li>Can you receive direct reservations?</li>
            <li>Does the calendar allow you to manage reservations, pricing, and availability?</li>
            <li>Is the channel manager synchronized with the software?</li>
            <li>Can you manage all units using a mobile phone?</li>
            <li>Does the supplier provide in-depth onboarding and 24/7 support?</li>
            <li>How long does it take to implement?</li>
            <li>Do they offer a free trial period?</li>
          </ul>
        </PUL>
        <P>
          It is essential to understand your needs before selecting the right software.{' '}
          <a href="https://app.rentl.io/en/sign-up">Start a Free Trial</a> and discover how Rentlio can help you manage
          your property.
        </P>
        <GreyBlock>
          <H2 id="4" className="sections">
            Vacation Rental Software Cost, Implementation, Onboarding, Support
          </H2>
          <H3>Cost</H3>
          <P>
            Vacation rental software cost varies based on multiple factors such as the number of units, complexity
            setup, and the number of integrations.{' '}
          </P>
          <H3>Implementation</H3>
          <P>
            The implementation usually takes a few days to complete. Modern cloud-based vacation rental software is easy
            and free to implement. On-prem solutions, however, require storage, high maintenance costs, and a long time
            to implement.
          </P>
          <H3>Onboarding</H3>
          <P>
            Many property owners are looking for an onboarding program before using the software. At Rentlio, we offer
            in-depth 1:1 onboarding demo sessions with our specialist, who guides you through the property setup
            process.{' '}
          </P>
          <H3>Support</H3>
          <P>
            Support is essential and should be included in your package. Rentlio’s support team is available 24/7 to
            help you solve any problems. With an average response time of less than 60 seconds and a problem resolution
            time of less than 4 minutes, we believe that the Rentlio support team is the best in the hospitality tech
            industry.{' '}
          </P>
          <H3>Key takeaways</H3>
          <PUL>
            <ul style={{ listStyleType: 'disc' }}>
              <li>Price varies based on many factors</li>
              <li>Implementation should be quick and smooth</li>
              <li>Always look for a cloud-based solution</li>
              <li>Ask for a 1:1 onboarding</li>
              <li>Find out what type of support is available</li>
            </ul>
          </PUL>
        </GreyBlock>
        <H2>What is Rentlio?</H2>
        <P>
          Rentlio is a cloud-based vacation rental and property management software provider suitable for properties of
          all sizes and different complexity setups. Founded in 2015 in Zadar, Croatia, by hospitality experts and
          enthusiasts to simplify the technical part of managing vacation rentals.
        </P>
        <P>
          In Rentlio, we believe technology should go hand in hand with hospitality – not one against the other.
          Currently used by over 1,500 customers worldwide, Rentlio allows you to focus on what you do best – providing
          an exceptional guest experience.
        </P>
        <P>
          <a href="https://rentl.io/en/vacation-rentals">Learn more about Rentlio</a> and how we can help you run your
          business more efficiently.
        </P>
        {/* <ButtonCTA onClick={(e) => handleClick(e)}>Saznajte više o Rentlio PMS-u</ButtonCTA>
        <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText> */}
        <SpaceBlock />
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
